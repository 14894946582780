import React from "react";
import nyheter from "../../assets/videos/nyheter.mp4";
import Navbar from "../navbar/Navbar";

const HeroSection = () => {
  return (
    <div className="relative w-full h-[544px] md:h-[544px] lg:h-[559px]">
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute inset-0   w-full h-[544px] md:h-[544px] lg:h-[559px] object-cover"
      >
        <source src={nyheter} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="bg-[#00354E] absolute   top-0  w-full h-full opacity-60" />
      <div className="relative z-10 ">
        <Navbar />

        <div className="flex flex-col items-center justify-center md:items-start h-full w-full">
          <div className=" mx-5  my-10 md:mt-7 lg:mt-14 md:leading-[70px] text-[20px] md:text-[64px] md:mx-16  md:text-start font-[400] md:font-[400] md:my-5 lg:my-0  text-white">
            <h1 className=" text-white font-[700] text-[64px] lg:text-[64px] leading-[45px] ">
              Nyheter
            </h1>

            <h2 className=" text-start max-w-[298px]  md:block text-[16px] md:text-[20px] font-[500] md:font-[500] md:leading-[30px] md:mt-5 md:max-w-[782px] lg:max-w-[908px] pt-4 md:pt-6 ">
              Här uppdaterar vi regelbundet och publicerar välresearchade
              artiklar, tips för energibesparing och säkerhetsrekommendationer.
              Du hittar även nyheter om vad som händer hos oss med nya projekt
              och annan övrig information.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
