import React from "react";
import noImage from "../../assets/images/noImage.jpg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const News = ({ allData, homeSlug, related }) => {
  const navigate = useNavigate();
  const findImage = (id) => {
    const url = allData?.includes?.Asset?.find((asset) => asset.sys.id === id);
    if (url) return "https:" + url?.fields?.file?.url;
  };

  return (
    related?.length > 0 && (
      <div
        className={`px-5  py-10  bg-[#EFF6E7] md:bg-inherit w-full md:w-auto  md:px-16 pb-32 lg:pb-0 lg:py-0  lg:px-28  2xl:p-10 2xl:pl-28  `}
      >
        <h2 className="text-[47.24px] font-[700]">Relaterat</h2>
        <p className="text-[12px] font-[300] lg:text-[20px] md:hidden 2xl:block ">
          <span className="lg:hidden">Ämne :</span> {homeSlug}
        </p>

        <div className="grid w-full mt-10 grid-cols-1    md:grid-cols-2 2xl:grid-cols-3 gap-24 md:gap-36">
          {related?.map(({ fields }, index) => (
            <div
              key={index}
              className=" max-w-[350px] cursor-pointer "
              onClick={() => navigate(`/nyheter/${fields?.homeSlug}`)}
            >
              <div
                // src={findImage(fields?.homePageImage?.sys?.id) ?? noImage}
                style={{
                  backgroundImage: `url(${
                    findImage(fields?.homePageImage?.sys?.id) ?? noImage
                  })`,
                }}
                width={325}
                height={197}
                alt="optimerade"
                className="w-[304px] h-[181px] md:w-[325px md:h-[197px] object-contain"
              />
              <p className="text-[#727272] uppercase text-[12px] mt-3  hidden md:block ">
                {fields?.homeSlug}
              </p>
              <p className="text-[#727272] text-[10px] font-[600] mt-3 hidden md:block">
                {moment(fields?.homeDateAndTime).format("DD-M-YYYY, h:mm")}
              </p>
              <h2 className="text-[32px] font-[700] mt-3">
                {fields?.homeHeading}
              </h2>
              <h3 className="text-[16px] mt-5">
                {fields?.homeParagraph?.content[0]?.content[0]?.value}
              </h3>
              <div
                className="flex justify-end"
                onClick={() => navigate(`/nyheter/${fields?.homeSlug}`)}
              >
                <button className="text-[15px] m-3  ">{"Läs mer >>"}</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default News;
