import bgVideo from "../../assets/videos/home.mp4";
import Navbar from "../navbar/Navbar";

const HeroSection = () => {
  return (
    <div className="relative h-[667px] md:h-[854px] lg:h-[978px] 2xl:h-[1080px]">
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute inset-0  object-cover w-full h-[667px] md:h-[854px] lg:h-[978px] 2xl:h-[1080px]"
      >
        <source src={bgVideo} type="video/mp4" />
      </video>

      <div className="bg-[#00354E] absolute   top-0  w-full h-full opacity-60" />
      <div className="relative z-10 ">
        <Navbar />
        <div className="flex flex-col items-center   h-full w-full">
          <div className="w-full md:leading-[70px] flex flex-col items-center  md:w-full text-[20px] md:text-[64px] px-10 md:px-16  md:text-start font-[400] md:font-[400] my-32  md:my-28 lg:my-44 2xl:my-40   text-white">
            <div className="text-center md:text-start">
              <h1 className="uppercase md:normal-case w-full md:max-w-[850px] lg:max-w-[1100px] ">
                EL, Teknik & Installationer för framtiden med{" "}
                <br className="lg:hidden" />
                <span className="uppercase md:normal-case text-[#89BF50] font-[700] hidden lg:inline ">
                  hållbara framsteg
                </span>
              </h1>
              <h1 className="uppercase md:normal-case text-[#89BF50] font-[700] lg:hidden">
                hållbara framsteg
              </h1>
              <h2 className="text-[12px] px-3  md:text-[20px] font-[400] md:font-[500] mt-5 w-full  md:leading-[30px] md:mt-5 md:max-w-[808px]  ">
                Med målet att vara marknadsledande, kombinerar Egestam och
                Mattsson expertkunskap, engagemang och innovation för att bygga
                varaktiga kundrelationer och leverera arbete med fokus på
                kvalitet, miljömedvetenhet och säkerhet.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
