import React from "react";
import Installation from "../../assets/images/Installation.jpg";
import Nybyggnation from "../../assets/images/Nybyggnation.jpg";
import Renovering from "../../assets/images/Renovering.jpg";
import Elbilsladdare from "../../assets/images/Elbilsladdare.jpg";
import Solceller from "../../assets/images/Solceller.jpg";
import EListyr from "../../assets/images/EListyr.png";
import sectionsbg from "../../assets/images/sectionsbg.png";
import sectionslg from "../../assets/images/section-lg-bg.png";
import jonatan from "../../assets/images/jonatan.jpg";
import daniel from "../../assets/images/daniel.jpg";
import mail from "../../assets/icons/mail.svg";
import { useNavigate } from "react-router-dom";

const datas = [
  {
    title: "Installation",
    image: Installation,
    link: "Installationer",
  },
  {
    title: "Nybyggnation",
    image: Nybyggnation,
    link: "Nybyggnation",
  },
  {
    title: "Renovering",
    image: Renovering,
    link: "Renovering",
  },
  {
    title: "Elbilsladdare",
    image: Elbilsladdare,
    link: "Elbilsladdare",
  },
  {
    title: "Solceller",
    image: Solceller,
    link: "Solceller",
  },
  {
    title: "EL i styr",
    image: EListyr,
    link: "EL i styr",
  },
];

const peoples = [
  {
    image: jonatan,
    name: "Jonatan Mattsson",
    description: "Affärschef - Delägare ",
    mail: "jonatan@emeldata.se",
  },
  {
    image: daniel,
    name: "Daniel Egestam",
    description: "Projektchef - Delägare",
    mail: "daniel@emeldata.se",
  },
];

const Sections = () => {
  const navigate = useNavigate();
  return (
    <>
      <h3 className="hidden md:block font-[700] text-[48px] mx-32 lg:mx-28 2xl:mx-40">
        Läs mer om våra tjänster
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-16 my-16 font-sans font-[300]">
        {datas.map((data, i) => (
          <div
            key={i}
            style={{
              backgroundImage: `url(${data.image})`,
            }}
            onClick={() => navigate(`/nyheter/${data.link}`)}
            className="text-white cursor-pointer text-[32px] w-[250px] h-[150px] lg:w-[270px] lg:h-[202px] 2xl:w-[336px] 2xl:h-[252px] bg-cover rounded-[600px] lg:rounded-[400] flex justify-center items-center relative  overflow-hidden"
          >
            <div className=" bg-black absolute w-full h-full opacity-60  " />
            <h3 className="z-[1] text-center">{data.title}</h3>
          </div>
        ))}
      </div>

      {/* mobile */}
      <div className="flex flex-col justify-center w-full  my-16 md:hidden gap-5 ">
        {peoples.map((data, i) => (
          <div
            key={i}
            style={{
              backgroundImage: `url(${sectionsbg})`,
            }}
            className="h-[367px]  w-full bg-cover bg-no-repeat flex flex-col lg:flex-row px-10  justify-center "
          >
            <img
              src={data.image}
              width={212}
              height={180}
              alt={data.image}
              className="rounded-[300px] w-[212px] h-[180px] object-cover"
            />
            <div className="mt-3">
              <h4 className="text-[24px] font-[600]">{data.name}</h4>
              <h4 className="text-[18px] font-[600]">{data.description}</h4>
              <a
                href={`mailto: ${data.mail}`}
                className="flex  items-center gap-2 mt-2 text-[20px]"
              >
                {" "}
                <img src={mail} width={20} height={20} alt="mail" /> Maila mig
              </a>
            </div>
          </div>
        ))}
      </div>

      <div className=" hidden md:flex lg:hidden mx-10  my-36">
        {peoples.map((data, i) => (
          <div
            key={i}
            className="h-[349px]  w-full bg-cover bg-no-repeat flex flex-col gap-5 items-center justify-center "
          >
            <img
              src={data.image}
              width={374}
              height={250}
              alt={data.image}
              className="rounded-[300px] object-cover w-[310px] h-[250px]"
            />
            <div className="mt-3 ">
              <h4 className="text-[24px] font-[600]">{data.name}</h4>
              <h4 className="text-[18px] font-[600]">{data.description}</h4>
              <a
                href={`mailto: ${data.mail}`}
                className="flex  items-center  gap-2 mt-2 text-[20px]"
              >
                <img src={mail} width={20} height={20} alt="mail" /> Maila mig
              </a>
            </div>
          </div>
        ))}
      </div>

      {/*  macbook  */}
      <div className="lg:flex flex-col justify-center w-full items-center my-20  hidden 2xl:hidden ">
        {peoples.map((data, i) => (
          <div
            key={i}
            style={{
              backgroundImage: `url(${sectionslg})`,
            }}
            className="h-[349px]  w-full bg-cover bg-no-repeat flex items-center pl-56 pt-10"
          >
            <img
              src={data.image}
              width={374}
              height={250}
              alt={data.image}
              className="rounded-[300px] object-cover w-[310px] h-[250px]"
            />
            <div className="mt-3 ml-48">
              <h4 className="text-[24px] font-[600]">{data.name}</h4>
              <h4 className="text-[18px] font-[600]">{data.description}</h4>
              <a
                href={`mailto: ${data.mail}`}
                className="flex  items-center  gap-2 mt-2 text-[20px]"
              >
                <img src={mail} width={20} height={20} alt="mail" /> Maila mig
              </a>
            </div>
          </div>
        ))}
      </div>

      {/* desktop */}
      <div className="2xl:flex flex-col justify-center w-full items-center my-16 hidden  ">
        <div
          style={{
            backgroundImage: `url(${sectionslg})`,
          }}
          className="h-[567px]  w-full bg-cover bg-no-repeat  flex items-center  justify-around"
        >
          {peoples.map((data, i) => (
            <div key={i} className="flex items-center gap-24">
              <img
                src={peoples[peoples.length - 1 - i].image}
                width={374}
                height={339}
                alt={peoples[peoples.length - 1 - i].image}
                className="rounded-[300px] object-cover w-[374px] h-[339px]"
              />
              <div className="mt-3">
                <h4 className="text-[24px] font-[600]">
                  {peoples[peoples.length - 1 - i].name}
                </h4>
                <h4 className="text-[18px] font-[600]">
                  {peoples[peoples.length - 1 - i].description}
                </h4>
                <a
                  href={`mailto:${peoples[peoples.length - 1 - i].mail}`}
                  className="flex items-center gap-2 mt-2 text-[20px]"
                >
                  <img src={mail} width={20} height={20} alt="mail" /> Maila mig
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sections;
