import React, { useEffect, useState } from "react";
import downArrowBlack from "../../assets/icons/downArrowBlack.svg";
import downArrowWhite from "../../assets/icons/downArrowWhite.svg";
import klimatmål from "../../assets/images/klimatmål.png";
import hallbar from "../../assets/images/hallbar.png";
import anstandiga from "../../assets/images/anstandiga.png";
import industri from "../../assets/images/industri.png";
import innovation from "../../assets/images/innovation.png";
import bekampa from "../../assets/images/bekampa.png";
import { useLocation } from "react-router-dom";

const klimatmal = [
  {
    id: 1,
    year: "2030",
    title: "Reduktion & Innovation",
    description:
      "Inom de kommande åren sätter vi fokus på att minska våra CO2-utsläpp med 20% från 2020 års nivåer. Vi kommer att investera i innovativa teknologier och arbetsmetoder som minskar vårt klimatavtryck och ökar energieffektiviteten i de fastigheter vi arbetar med.",
  },
  {
    id: 2,
    year: "2035",
    title: "Partnerskap & Ledarskap",
    description:
      "Till 2035 siktar vi på att hjälpa våra kunder att minska deras utsläpp med 50 % jämfört med 2020, samtidigt som vi uppnår samma minskning i vår egen verksamhet. Vi kommer att fördjupa våra partnerskap och ta en ledande roll i branschen för att driva på den gröna omställningen.",
  },
  {
    id: 3,
    year: "2040",
    title: "Förnyelse & Optimering",
    description:
      "Fram till 2040 kommer vårt arbete att fokusera på att optimera användningen av förnybar energi och maximera resurseffektiviteten i alla våra projekt. Vi strävar efter att våra fastighetslösningar ska vara helt självförsörjande när det gäller energi och minimera avfall till nära noll.",
  },
  {
    id: 4,
    year: "2045",
    title: "Netto noll",
    description:
      "Vår resa mot 2045 är inte bara en plan, det är ett löfte om att aktivt forma en hållbar framtid där vi och våra kunder tillsammans kan göra en verklig skillnad. Vår slutdestination är en verksamhet med netto noll utsläpp senast 2045. Vi kommer att ha fullt integrerat hållbara principer, från design och installation till underhåll och service. ",
  },
];

const datas = [
  {
    id: 1,
    icon: hallbar,
    title: "Hållbar energi för alla",
    description:
      "Genom att integrera solenergilösningar och energieffektiviseringsmetoder i våra installationer, arbetar vi för att göra hållbar energi tillgänglig och överkomlig.",
  },
  {
    id: 2,
    icon: anstandiga,
    title: " Anständiga arbetsvillkor och ekonomisk tillväxt",
    description:
      "Vi satsar på att vara en ansvarsfull arbetsgivare som främjar säkra arbetsförhållanden, samt stödjer kontinuerlig utbildning och utveckling av våra medarbetare.",
  },
  {
    id: 3,
    icon: industri,
    title: " Industri, innovation och infrastruktur",
    description:
      "Vi bidrar till byggandet av motståndskraftig infrastruktur och främjar inkluderande och hållbar industrialisering genom att införa innovativa och hållbara tekniska lösningar.",
  },
  {
    id: 4,
    icon: innovation,
    title: "Hållbara städer och samhällen",
    description:
      "Genom vårt arbete med att utveckla och renovera fastigheter och infrastruktur strävar vi efter att skapa och underhålla gröna och tillgängliga urbana områden.",
  },
  {
    id: 5,
    icon: bekampa,
    title: "Bekämpa klimatförändringarna",
    description:
      "Vårt engagemang för att minska koldioxidutsläpp och vår inriktning på klimatanpassning i alla våra projekt reflekterar vårt bidrag till kampen mot klimatförändringar.",
  },
];

export const DropDownSection = () => {
  const [nybyggnation, setNybyggnation] = useState(false);
  const [renovering, setRenovering] = useState(false);
  const [installation, setInstallation] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(0);

  const location = useLocation();
  useEffect(() => {
    if (location?.state) {
      setRenovering(true);
      const targetSection = document.getElementById("target-section-mobile");
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location?.state]);

  return (
    <div className="w-full md:hidden">
      <div className="bg-[#89BF50]">
        <div
          className=" h-[141px] w-full text-[40px] font-[300] flex justify-between items-center px-8 cursor-pointer"
          onClick={() => setNybyggnation(!nybyggnation)}
        >
          <h2 className="text-white font-sans ">Vision</h2>
          <img
            src={downArrowWhite}
            width={18}
            height={18}
            alt="downArrowWhite"
            className={`${nybyggnation && "rotate-180"}`}
          />
        </div>
        {nybyggnation && (
          <div className=" w-full px-5 flex flex-col items-center ">
            <h3 className="text-[16px] text-center ">
              Egestam och Mattsson har en hållbarhetsvision som sträcker sig
              till 2045, där målet är att nå netto noll utsläpp. Vi ser
              hållbarhet som kärnan i vår verksamhet och ett grundläggande
              åtagande i allt vi gör. Varje femårsperiod fram till 2045 innebär
              nya delmål och strategier som är anpassade för att gradvis föra
              oss närmare vår ambition om ett klimatneutralt samhälle.
            </h3>
            <h3
              className="underline text-white mb-20 mt-10"
              onClick={() => {
                setNybyggnation(!nybyggnation);
                setRenovering(!renovering);
              }}
            >
              Så här jobbar vi med hållbarhet
            </h3>
          </div>
        )}
      </div>

      <div className="bg-[#00354E]  w-full " id="target-section-mobile">
        <div
          className=" h-[141px] w-full text-[40px] font-[300] flex justify-between items-center px-8 cursor-pointer  "
          onClick={() => setRenovering(!renovering)}
        >
          <h2 className="text-white font-sans">Klimatmål</h2>
          <img
            src={downArrowWhite}
            width={18}
            height={18}
            alt="downArrowWhite"
            className={`${renovering && "rotate-180"}`}
          />
        </div>
        {renovering && (
          <div className=" w-full  bg-white  ">
            <img
              src={klimatmål}
              width={400}
              height={258}
              alt="klimatmål"
              className="w-full h-full"
            />
            {klimatmal.map((data) => (
              <div
                key={data.id}
                className={`px-8 cursor-pointer pt-3  ${
                  data.id % 2 !== 0 ? "bg-white" : " bg-[#E9F3F9]"
                } `}
                onClick={() => {
                  setId(data.id);
                  setShow(!show);
                }}
              >
                <h3 className="text-[20px] font-[700]">{data.year}</h3>
                <h3 className="text-[20px] font-[700]">{data.title} </h3>
                {show && data.id === id && (
                  <p className="text-[16px] my-5 max-w-[280px] ">
                    {data.description}
                  </p>
                )}
                <p className="text-[10px] font-[100] text-end  pb-2 ">
                  {show && data.id === id ? "Minimera" : "Läs mer"}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="bg-[#89BF50]">
        <div
          className=" h-[141px] w-full text-[40px]  font-[300] flex justify-between items-center px-8 cursor-pointer"
          onClick={() => setInstallation(!installation)}
        >
          <h2 className="font-sans  ">Globala målen</h2>
          <img
            src={downArrowBlack}
            width={18}
            height={18}
            alt="downArrowWhite"
            className={`${installation && "rotate-180"}`}
          />
        </div>
        {installation && (
          <div className=" flex flex-col items-center justify-center  mb-10 w-full bg-white pt-10">
            <h2 className="text-[20px] font-[700]  bg-inherit  md:bg-[#00354E] md:h-[127px]   w-full  md:text-white text-center md:text-[32px] md:pt-10 lg:h-[141px]  lg:text-[48px] ">
              {" "}
              Hållbar framtid en del ev vår affär
            </h2>
            <h3 className="text-[20px] font-[600] max-w-[330px] text-center md:hidden ">
              -Vår policy för att bidra till de Globala Målen
            </h3>
            <div className="flex flex-col lg:flex-row flex-wrap justify-center items-center gap-10 2xl:gap-x-72 w-full  ">
              {datas.map((data) => (
                <div
                  key={data.id}
                  className=" md:max-w-[604px] lg:w-[580px] 2xl:w-[631px] flex flex-col justify-center items-center gap-4 mt-10 md:mt-20 "
                >
                  <img src={data.icon} width={198} height={198} alt="hallbar" />
                  <h3 className="text-[16px] max-w-[275px] font-[700] text-center lg:text-[20px] ">
                    {data.title}
                  </h3>
                  <p className="text-[16px] max-w-[300px] md:text-[20px] text-center ">
                    {data.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
