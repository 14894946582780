import React from "react";
import vartMoto from "../../assets/images/vartMoto.png";
import jobba from "../../assets/images/jobba.png";
import after from "../../assets/icons/after.svg";
import { useNavigate } from "react-router-dom";

const VartSection = () => {
  const navigate = useNavigate();
  return (
    <div className=" md:py-14 flex flex-col 2xl:flex-row md:items-center gap-10 w-full 2xl:justify-center 2xl:gap-52">
      <div className="px-5 text-[20px] max-w-[575px] md:hidden 2xl:block ">
        <img
          src={vartMoto}
          width={558}
          height={344}
          alt="vartMoto"
          className="w-[323px] h-[266px] 2xl:w-[585px] 2xl:h-[344px] rounded-[10px] object-contain "
        />
        <h4 className=" md:mt-5   font-[700] text-[20px] md:text-[32px] lg:text-[48px] font-sans ">
          VÅRT MOTTO{" "}
        </h4>{" "}
        <h2 className="font-[400]">
          Kontrollerad riskhantering med förankring i god affärsetik
        </h2>
        <h3 className="mt-4 font-[300] text-[#00354E]">
          Egestam och Mattsson strävar efter att generera stark avkastning genom
          en stabil ekonomisk tillväxt. Vår verksamhet präglas av en noggrant
          kontrollerad riskhantering med förankring i god affärsetik. Ett
          centralt fokus i vår affärsstrategi är att aktivt minska vår
          miljöpåverkan, vilket vi ser som en avgörande aspekt för att främja en
          hållbar framtid.
        </h3>
      </div>

      <div
        className="w-full h-[415px] md:h-[361px] lg:h-[537px] 2xl:h-[583px]  md:max-w-[762px] lg:max-w-[921px] 2xl:max-w-[770px]   relative bg-no-repeat bg-cover md:rounded-[28px]  2xl:rounded-b-none 2xl:rounded=[12px] "
        style={{ backgroundImage: `url(${jobba})` }}
      >
        <div className="w-full h-full bg-[#000000] md:bg-[#00354E]  2xl:bg-[#000000] opacity-60 md:rounded-[28px] 2xl:rounded-b-none 2xl:rounded=[12px]" />
        <div className="absolute top-0  h-full text-white flex flex-col justify-center gap-6   px-5 md:px-16 lg:px-32 ">
          <h2 className="text-[32px] md:text=[48px]">Jobba hos oss</h2>
          <h3 className="text-[20px] max-w-[320px] md:max-w-full">
            Är du intresserad av att vara med och forma framtidens infrastruktur
            och fastighetslösningar? <br /> Bli en del av vårt team & hjälp oss
            att bygga en hållbar framtid
          </h3>
          <button
            className="w-[275px]  bg-[#00354E] py-2 rounded-[48px] text-[16px] flex justify-center items-center gap-2 px-2"
            onClick={() => navigate("/jobba-med-ss/Jobba med oss")}
          >
            Läs mer om lediga tjänster
            <img src={after} alt="after" width={18} height={18} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VartSection;
