import React from "react";
import Hero from "../components/kontakt/Hero";
import Section from "../components/kontakt/Section";
import FooterSection from "../components/FooterSection";
import Address from "../components/kontakt/Address";
import ScrollToTop from "../components/scrollToTop";
import { getMetaDescription } from "../utils/metaDescriptions";
import { Helmet } from "react-helmet";

const Kontakt = () => {
  const metaDescription = getMetaDescription("kontakt");
  return (
    <>
      <Helmet>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <ScrollToTop />
      <Hero />
      <Address />
      <Section />
      <FooterSection />
    </>
  );
};

export default Kontakt;
