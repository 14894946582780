import React from "react";

const  Egestam = () => {
  return (
    <div className="bg-[#89BF50] w-full h-[427px] md:bg-opacity-15 hidden md:flex justify-center items-center  text-white md:text-black px-5 md:px-20   ">
      <div className="lg:w-[800px] flex flex-col items-center justify-center">
        <h3 className="text-center text-[20px]  font-[400] max-w-[775px]">
          Egestam och Mattsson har en hållbarhetsvision som sträcker sig till
          2045, där målet är att nå netto noll utsläpp. Vi ser hållbarhet som
          kärnan i vår verksamhet och ett grundläggande åtagande i allt vi gör.
          Varje femårsperiod fram till 2045 innebär nya delmål och strategier
          som är anpassade för att gradvis föra oss närmare vår ambition om ett
          klimatneutralt samhälle.
        </h3>
      </div>
    </div>
  );
};

export default Egestam;
