import React from "react";
import service from "../../assets/videos/service.mp4";
import Navbar from "../navbar/Navbar";

const HeroSection = () => {
  return (
    <div className="relative w-full h-[690px]  md:h-[814px]">
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute inset-0   w-full h-[690px]  md:h-[814px] object-cover"
      >
        <source src={service} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="bg-[#00354E] absolute   top-0  w-full h-full opacity-60" />
      <div className="relative z-10 ">
        <Navbar />

        <div className=" flex flex-col justify-center items-center mx-5  my-24 md:leading-[70px] text-[20px] lg:text-[64px] md:mx-16  md:text-start font-[400] md:font-[400] md:my-32 lg:my-28   text-white">
          <h1 className=" text-white text-center md:text-start font-[400] text-[32px] md:text-[48px] lg:text-[64px] leading-[45px] lg:leading-[60px] ">
            Våra Tjänster för{" "}
            <span className="font-[700] ">
              <span className="text-[#89BF50] ">hållbara</span> lösningar
            </span>
          </h1>

          <h2 className="px-2 text-center max-w-[320px]  md:hidden text-[16px]   font-[500] md:font-[500] md:leading-[30px] md:mt-5 md:max-w-[782px] lg:max-w-[1000px] pt-4 ">
            Egestam & Mattsson grundades 2012, vi är experter inom el- och
            datanätverksinstallationer. Fokuserade på att leverera miljövänliga
            och innovativa lösningar för fastigheter och infrastruktur. Läs mer
            om hur vi kan hjälpa ditt företag, BRF eller dig som fastighetsägare
            till mer hållbara lösningar för både miljö och ekonomi.
          </h2>
          <h2 className=" text-start hidden  md:block text-[16px] md:text-[20px]  font-[500] md:font-[500] md:leading-[30px] md:mt-5 md:max-w-[782px] lg:max-w-[1000px] pt-4 ">
            Egestam & Mattsson grundades 2012, vi är experter inom el- och
            datanätverksinstallationer. Ett entreprenörsdrivet företag som drivs
            med ett team engagerade medarbetare. Vi kombinerar gedigen teknisk
            kunskap med ett starkt engagemang för hållbarhet, fokuserade på att
            leverera miljövänliga och innovativa lösningar för fastigheter och
            infrastruktur.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
