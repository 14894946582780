import React from "react";
import arrowCercle from "../../assets/images/arrowCercle.png";
import { useNavigate } from "react-router-dom";

const Välkommen = () => {
  const navigate = useNavigate();
  return (
    <div className="py-14  px-6 md:bg-[#CCCCCC] flex   flex-col  justify-center w-full md:min-h-[331px] lg:h-[470px]  ">
      <div className="md:mx-20 lg:mx-32 2xl:mx-56  lg:max-w-[1246px] ">
        <div className="flex gap-4 ">
          <img
            src={arrowCercle}
            alt={arrowCercle}
            width={73}
            height={73}
            className="w-[30px] h-[30px] md:h-[58px] md:w-[58px] lg:w-[73px] lg:h-[73px] "
          />
          <h2 className="  font-[700] text-[18px] md:text-[32px] lg:text-[40px]  font-sans ">
            Välkommen till EM EL & DATA AB{" "}
          </h2>
        </div>
        <h3 className="text-[18px] md:text-[20px] lg:text-[24px]  mt-8 md:mt-0 md:ml-20 lg:ml-0 lg:mt-8">
          Egestam & Mattsson, grundat 2012, är experter inom el- och
          datanätverksinstallationer. Vi är ett entreprenörsdrivet företag
          framdrivet av ett team engagerade medarbetare. Vi kombinerar gedigen
          teknisk kunskap med ett starkt engagemang för hållbarhet och är
          fokuserade på att leverera miljövänliga, innovativa lösningar för
          fastigheter och infrastruktur
        </h3>
      </div>
      <div
        className="w-full flex justify-center md:justify-start"
        onClick={() => navigate("/nyheter/Om oss")}
      >
        <button className="bg-[#00354E] w-[244px] h-[43px] rounded-[54px] mt-8 text-[18px] font-[600] text-white md:hidden ">
          Om oss
        </button>
      </div>
    </div>
  );
};

export default Välkommen;
