const metaDescriptions = {
  home: "Vi utför installationer inom el och nätverksinstallationer i södra Sverige. Allt för fastighetsägare, företag och bostadsrättsföreningars behov inom el.",
  hallbarhet:
    "Vi utför installationer inom el och nätverksinstallationer i södra Sverige. Allt för fastighetsägare, företag och bostadsrättsföreningars behov inom el.",
  nyheter:
    "Vi utför installationer inom el och nätverksinstallationer i södra Sverige. Allt för fastighetsägare, företag och bostadsrättsföreningars behov inom el.",
  tjanster:
    "Vi utför installationer inom el och nätverksinstallationer i södra Sverige. Allt för fastighetsägare, företag och bostadsrättsföreningars behov inom el.",
  kontakt:
    "Vi utför installationer inom el och nätverksinstallationer i södra Sverige. Allt för fastighetsägare, företag och bostadsrättsföreningars behov inom el.",
};

export const getMetaDescription = (pageName) => {
  return metaDescriptions[pageName] || "";
};
