import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import logoSticky from "../../assets/images/logoSticky.png";
import buttonMeny from "../../assets/icons/buttonMeny.svg";
import toggle from "../../assets/icons/toggle.svg";
import close from "../../assets/icons/close.svg";
import { useLocation, useNavigate } from "react-router-dom";

const links = [
  { id: 1, label: "Hem", link: "" },
  { id: 2, label: "Hållbarhet", link: "hallbarhet" },
  { id: 3, label: "Tjänster", link: "tjanster" },
  { id: 4, label: "Nyheter", link: "nyheter" },
  { id: 5, label: "Kontakt", link: "kontakt" },
  { id: 6, label: "Jobba med oss", link: "jobba-med-ss/Jobba med oss" },
];

const Navbar = () => {
  const location = useLocation();
  const [showComponent, setShowComponent] = useState(false);
  const [open, setOpne] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition >= 150) {
        setShowComponent(true);
      } else {
        setShowComponent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navSelect = (path) => {
    if (path === "/") {
      return true;
    } else if (location.pathname.split("/")[1] === path.split("/")[0]) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="flex w-full p-5 md:px-14 md:py-6 lg:px-16 2xl:px-36 lg:py-10   justify-between  items-center  font-sans font-thin z-50">
        <img
          src={logo}
          width={266}
          height={167}
          alt="logo"
          className="w-[110px] h-[69px] md:w-[182px] md:h-[115px] 2xl:w-[266px] 2xl:h-[167px] cursor-pointer "
          onClick={() => navigate("/")}
        />
        <img
          src={open ? close : buttonMeny}
          width={48}
          height={48}
          alt="buttonMeny"
          className="w-[48px] h-[48px] lg:hidden cursor-pointer"
          onClick={() => setOpne(!open)}
        />
        <div className="hidden lg:flex w-[73%] 2xl:w-[62%] justify-between lg:text-[24px] text-white">
          {links.map((data) => (
            <div
              key={data.id + "navbar"}
              className={`cursor-pointer ${
                navSelect(data.link) && "text-[#89BF50]"
              }`}
              onClick={() => navigate("/" + data.link)}
            >
              {data.label}
            </div>
          ))}
        </div>
      </div>
      {showComponent && (
        <div
          className={` fixed top-0 w-full z-50   lg:px-16 2xl:px-36  bg-[#89BF50] font-sans font-thin ${
            open && "h-full"
          }  `}
        >
          <div className="flex  p-5 md:px-10 lg:px-16 lg:gap-24 2xl:gap-56 justify-between   items-center ">
            <img
              src={logoSticky}
              width={266}
              height={167}
              alt="logo"
              className="w-[99px] h-[63px] md:w-[85px] md:h-[54px] lg:w-[119px] lg:h-[61px] cursor-pointer"
              onClick={() => navigate("/")}
            />
            <img
              src={open ? close : buttonMeny}
              width={48}
              height={48}
              alt="buttonMeny"
              className={` ${
                open ? "w-[30px] h-[30px]" : " w-[48px] h-[48px]"
              } lg:hidden cursor-pointer z-20`}
              onClick={() => setOpne(!open)}
            />
            <div className="hidden lg:flex  w-full justify-between lg:text-[18px] xl:text-[24px] text-white">
              {links.map((data) => (
                <div
                  key={data.id + "navbar"}
                  className={`cursor-pointer ${
                    navSelect(data.link) && "text-[#00354E]"
                  } `}
                  onClick={() => navigate("/" + data.link)}
                >
                  {data.label}
                </div>
              ))}
            </div>
          </div>
          {open && (
            <div className="w-full h-full  absolute top-0 px-5 py-24 ">
              <div className="mt-14">
                {links.map((data) => (
                  <div
                    key={data.id + "navbar"}
                    className={`cursor-pointer text-[20px] font-[600] w-full  border-[#00354E] b py-3 flex justify-between md:pr-10 ${
                      data.id === 1 ? "border-y-[1px]" : "border-b-[1px]"
                    } ${navSelect(data.link) && "text-white"} `}
                    onClick={() => navigate("/" + data.link)}
                  >
                    {data.label}
                    <img src={toggle} width={8} height={16} alt="toggle" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {open && (
        <div className="w-full h-[600px]  bg-[#89BF50] absolute top-0 px-5 py-5 z-40 ">
          <img
            src={logoSticky}
            width={266}
            height={167}
            alt="logo"
            className="w-[99px] h-[63px] md:w-[85px] md:h-[54px] lg:w-[119px] lg:h-[61px] cursor-pointer"
            onClick={() => navigate("/")}
          />
          <div className="relative">
            <img
              src={open ? close : buttonMeny}
              width={48}
              height={48}
              alt="buttonMeny"
              className={` ${
                open ? "w-[30px] h-[30px]" : " w-[48px] h-[48px]"
              }  lg:hidden cursor-pointer absolute right-3 -top-12`}
              onClick={() => setOpne(!open)}
            />
          </div>
          <div className="mt-14">
            {links.map((data) => (
              <div
                key={data.id + "navbar"}
                className={`cursor-pointer text-[20px] font-[600] w-full  border-[#00354E] b py-3 flex justify-between md:pr-10 ${
                  data.id === 1 ? "border-y-[1px]" : "border-b-[1px]"
                } ${navSelect(data.link) && "text-white"} `}
                onClick={() => navigate("/" + data.link)}
              >
                {data.label}
                <img src={toggle} width={8} height={16} alt="toggle" />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
