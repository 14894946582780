import React, { useEffect, useState } from "react";
import noImage from "../../assets/images/noImage.jpg";
import bravida from "../../assets/images/bravida.png";
import after from "../../assets/icons/after.svg";
import { api } from "../../api/axios";
import { useNavigate } from "react-router-dom";

const ProjektSection = () => {
  const [datas, setDatas] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    api
      .get(
        "/entries?access_token=2BMWcZki8_EUyB_VwhdGv0N2rmx5YbIUaKoipK-tFaE&content_type=projectPost"
      )
      .then(({ data }) => {
        setDatas(data);
      })
      .catch((error) => alert(error.message));
  }, []);

  datas?.items?.sort(
    (a, b) => new Date(b.sys.updatedAt) - new Date(a.sys.updatedAt)
  );
  const findImage = (id) => {
    const url = datas?.includes?.Asset?.find((asset) => asset.sys.id === id);
    if (url) return "https:" + url?.fields?.file?.url;
  };

  return (
    <div className="hidden lg:flex py-10 px-5   md:px-16 lg:px-20  gap-28  ">
      <div>
        <h2 className="text-[48px] font-[600] mb-10">Projekt</h2>

        <div className="flex gap-28">
          {datas?.items?.slice(0, 2)?.map(({ fields }, index) => (
            <div
              key={index}
              className="max-w-[373px] cursor-pointer"
              onClick={() =>
                navigate(`/nyheter/${fields?.homeSlug}`, {
                  state: true,
                })
              }
            >
              <div
                // src={findImage(fields?.projectImage?.sys?.id) ?? noImage}
                style={{
                  backgroundImage: `url(${
                    findImage(fields?.projectImage?.sys?.id) ?? noImage
                  })`,
                }}
                width={373}
                height={236}
                alt="projekt"
                className="w-[373px] h-[236px] bg-contain bg-no-repeat"
              />
              <h2 className="uppercase text-[#727272] text-[12px] font-[600] mt-5">
                {fields?.location}
              </h2>
              <h3 className="text-[22.86px] mt-5">{fields?.projectHeading}</h3>
            </div>
          ))}
        </div>
      </div>
      <div className="lg:hidden 2xl:block">
        <h2 className="text-[48px] font-[600] mb-10">Hör av dig till oss</h2>
        <img
          src={bravida}
          alt="bravida"
          width={499}
          height={274}
          className="w-[338px] h-[183px] object-cover lg:w-[443px] lg:h-[232px] "
        />
        <h2 className="text-[20px] font-[400] max-w-[338px] mt-6 lg:mt-3   ">
          Behöver du hjälp med något ? Kontakta oss idag!
        </h2>
        <div className="flex gap-5">
          <button
            className="w-[233px] bg-[#00354E] py-2 rounded-[48px] text-white mt-4 flex justify-center items-center gap-2"
            onClick={() => navigate("/kontakt")}
          >
            Hitta till vårt kontor
            <img
              src={after}
              alt="after"
              width={18}
              height={18}
              // className="absolute bottom-1 right-24 md:right-5"
            />
          </button>
          <a
            href="tel:+46406170166"
            className="w-[198px] bg-[#00354E] py-2 rounded-[48px] text-white mt-3 justify-center items-center flex gap-2"
          >
            Ring oss direkt
            <img src={after} alt="after" width={18} height={18} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjektSection;
