import React, { useState } from "react";
import downArrowWhite from "../../assets/icons/downArrowWhite.svg";
import downArrowBlack from "../../assets/icons/downArrowBlack.svg";
import { useNavigate } from "react-router-dom";

const DropDownSection = () => {
  const navigate = useNavigate();
  const [nybyggnation, setNybyggnation] = useState(false);
  const [renovering, setRenovering] = useState(false);
  const [installation, setInstallation] = useState(false);
  return (
    <div className="w-full  ">
      <div className="bg-[#00354E] md:px-20 lg:px-28 2xl:px-52">
        <div
          className=" h-[141px] w-full text-[35px] md:text-[48px] text-white font-[300] flex justify-between items-center px-6 cursor-pointer "
          onClick={() => setNybyggnation(!nybyggnation)}
        >
          Nybyggnation
          <img
            src={downArrowWhite}
            width={18}
            height={18}
            alt="downArrowWhite"
            className={`${nybyggnation && "rotate-180"}`}
          />
        </div>
        {nybyggnation && (
          <div className="bg-[#00354E] w-full px-8 text-white max-w-[380px] lg:max-w-[1220px]  ">
            <p className="text-[16px] lg:text-[20px] font-[300] ">
              Vid nybyggnation, oavsett om det gäller bostäder, kommersiella
              lokaler eller kontor, är kvalitetsinstallationer av el och
              datanätverk kritiska. Dessa installationer omfattar en rad
              tjänster från belysning och uppvärmning till avancerade
              datanätverk och kommunikationssystem. Integration av moderna
              teknologier som smarta hem- och kontorslösningar, samt
              energieffektiva installationer, är centrala för att skapa en
              hållbar, säker och effektiv miljö anpassad för framtida behov. Som
              experter på området är Egestam & Mattsson de du kan lita på för
              att leverera högkvalitativa installationer. Kontakta oss för att
              ta det första steget mot en framtidssäker fastighet.
            </p>
            <div className="w-full flex justify-center md:justify-start">
              <button
                className="w-[244px] bg-white h-[43px] rounded-[54px] text-[#00354E] text-[18px] font-[600] mb-10 mt-5 md:my-10"
                onClick={() => navigate("/kontakt")}
              >
                Kontakta oss
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="bg-[#89BF50] md:px-20 lg:px-28  2xl:px-52">
        <div
          className=" h-[141px] w-full text-[35px] md:text-[48px] font-[300] flex justify-between items-center  px-6 cursor-pointer"
          onClick={() => setRenovering(!renovering)}
        >
          Renovering
          <img
            src={downArrowBlack}
            width={18}
            height={18}
            alt="downArrowWhite"
            className={`${renovering && "rotate-180"}`}
          />
        </div>
        {renovering && (
          <div className="bg-[#89BF50] w-full px-8   ">
            <p className="text-[16px] lg:text-[20px] font-[300]">
              Egestam och Mattsson erbjuder omfattande tjänster för renovering
              inom el och installation, anpassade för både bostäder och
              kommersiella fastigheter. Vår expertis täcker allt från
              uppgradering av befintliga elsystem till installation av moderna,
              energieffektiva lösningar. Vi förstår vikten av att kombinera
              funktionalitet med estetik och arbetar nära våra kunder för att
              säkerställa att varje projekt möter deras specifika behov och
              önskemål. Vårt team är specialiserat på att leverera
              högkvalitativa resultat, från planering till slutförande, med
              fokus på säkerhet, hållbarhet och teknisk innovation. Kontakta oss
              för att diskutera dina renoveringsbehov och få en skräddarsydd
              offert från branschens experter.
            </p>

            <div className="w-full flex justify-center md:justify-start">
              <button
                className="w-[244px] bg-[#00354E]  md:bg-white h-[43px] rounded-[54px] text-white md:text-[#00354E] text-[18px] font-[600]  mb-10 mt-5 md:my-10"
                onClick={() => navigate("/kontakt")}
              >
                Kontakta oss
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="bg-[#00354E] md:px-20 lg:px-28 2xl:px-52">
        <div
          className=" h-[141px] w-full text-[35px] md:text-[48px] text-white font-[300] flex justify-between items-center px-6 cursor-pointer"
          onClick={() => setInstallation(!installation)}
        >
          Installation
          <img
            src={downArrowWhite}
            width={18}
            height={18}
            alt="downArrowWhite"
            className={`${installation && "rotate-180"}`}
          />
        </div>
        {installation && (
          <div className="bg-[#00354E] w-full px-8 text-white  ">
            <p className="text-[16px] lg:text-[20px] font-[300]">
              Egestam och Mattsson erbjuder specialanpassade
              installationstjänster för moderna värmesystem, smarta hem,
              effektiva kontorslösningar, avancerade säkerhetssystem och
              omfattande datainstallationer. Vår breda erfarenhet garanterar
              installationer som inte bara ökar komforten utan även
              energieffektiviteten och säkerheten i din fastighet. Vi engagerar
              oss i ett nära samarbete med våra kunder för att förstå deras
              unika behov, säkerställa funktionell och estetiskt tilltalande
              design och implementera de senaste innovationerna. Från grundlig
              planering till precision i utförandet, är vårt expertteam
              dedikerat till detaljer och de högsta standarderna av säkerhet och
              hållbarhet. Kontakta Egestam och Mattsson för att utforska hur vi
              kan transformera ditt utrymme till en intelligent och energismart
              miljö.
            </p>
            <div className="w-full flex justify-center md:justify-start">
              <button
                className="w-[244px] bg-white h-[43px] rounded-[54px] text-[#00354E] text-[18px] font-[600] mb-10 mt-5 md:my-10"
                onClick={() => navigate("/kontakt")}
              >
                Kontakta oss
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDownSection;
