import React from "react";
import HeroSection from "../components/nyheter/HeroSection";
import Contents from "../components/nyheter/Contents";
import FooterSection from "../components/FooterSection";
import ScrollToTop from "../components/scrollToTop";
import { getMetaDescription } from "../utils/metaDescriptions";
import { Helmet } from "react-helmet";

const Nyheter = () => {
  const metaDescription = getMetaDescription("nyheter");
  return (
    <div className="flex w-full flex-col justify-center items-center">
      <Helmet>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <ScrollToTop />
      <HeroSection />
      <Contents />
      <FooterSection />
    </div>
  );
};

export default Nyheter;
