import React from "react";
import vartMoto from "../../assets/images/vartMoto.png";
import bravida from "../../assets/images/bravida.png";
import after from "../../assets/icons/after.svg";
import { useNavigate } from "react-router-dom";

const VartMotoSectionMDLG = () => {
  const navigate = useNavigate();
  return (
    <div className=" hidden md:flex 2xl:hidden  justify-between w-full px-10 my-20 ">
      <div className="px-10  w-[451px] lg:w-[575px]  2xl:block ">
        <img
          src={vartMoto}
          width={558}
          height={344}
          alt="vartMoto"
          className="w-[363px] h-[191px] lg:w-[558px] lg:h-[255px]  object-cover rounded-[10px]  "
        />
        <h2 className="mt-10   font-[700] text-[32px] lg:text-[48px] text-[#003A3A] ">
          VÅRT MOTTO{" "}
        </h2>{" "}
        <h3 className="font-[400] text-[16px] lg:text-[20px]">
          Kontrollerad riskhantering med förankring i god affärsetik
        </h3>
        <h3 className="mt-4 font-[300] text-[14px] text-[#00354E] lg:text-[20px]">
          Egestam och Mattsson strävar efter att generera stark avkastning genom
          en stabil ekonomisk tillväxt. Vår verksamhet präglas av en noggrant
          kontrollerad riskhantering med förankring i god affärsetik. Ett
          centralt fokus i vår affärsstrategi är att aktivt minska vår
          miljöpåverkan, vilket vi ser som en avgörande aspekt för att främja en
          hållbar framtid.
        </h3>
      </div>
      <div className="flex flex-col">
        <h2 className="text-[32px] font-[700] hidden 2xl:block ">
          Hör av dig till oss
        </h2>
        <img
          src={bravida}
          alt="bravida"
          width={499}
          height={274}
          className="w-[338px] h-[183px] object-cover lg:w-[499px] lg:h-[274px] "
        />
        <h2 className="text-[32px] font-[700] mt-10 2xl:hidden ">
          Hör av dig till oss
        </h2>
        <h2 className="text-[20px] font-[400] max-w-[338px] mt-6 lg:mt-3   ">
          Behöver du hjälp med något ? Kontakta oss idag!
        </h2>
        <button
          className="w-[233px] bg-[#00354E] py-2 rounded-[48px] text-white mt-4 flex justify-center items-center gap-2"
          onClick={() => navigate("/kontakt")}
        >
          Hitta till vårt kontor
          <img
            src={after}
            alt="after"
            width={18}
            height={18}
            // className="absolute bottom-1 right-24 md:right-5"
          />
        </button>
        <a
          href="tel:+46406170166"
          className="w-[198px] bg-[#00354E] py-2 rounded-[48px] text-white mt-3 justify-center items-center flex gap-2"
        >
          Ring oss direkt
          <img
            src={after}
            alt="after"
            width={18}
            height={18}
            // className="absolute bottom-1 right-24 md:right-5"
          />
        </a>
      </div>
    </div>
  );
};

export default VartMotoSectionMDLG;
