import React, { useEffect, useState } from "react";
import afterBlack from "../../assets/icons/afterBlack.svg";
import downArrowBlack from "../../assets/icons/downArrowBlack.svg";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/axios";
import noImage from "../../assets/images/noImage.jpg";
import moment from "moment";

const Contents = () => {
  const [datas, setDatas] = useState([]);
  const [assets, setAssets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("Kategori");
  const [limit, setLimit] = useState(6);
  const navigate = useNavigate();
  useEffect(() => {
    api
      .get(
        "/entries?access_token=2BMWcZki8_EUyB_VwhdGv0N2rmx5YbIUaKoipK-tFaE&content_type=newsPosts2"
      )
      .then(({ data }) => {
        setDatas(data?.items);
        setAssets(data?.includes?.Asset);
      })
      .catch((error) => alert(error.message));

    api
      .get("/tags?access_token=2BMWcZki8_EUyB_VwhdGv0N2rmx5YbIUaKoipK-tFaE")
      .then(({ data }) => {
        setCategories(data?.items);
      })
      .catch((error) => alert(error.message));
  }, []);

  const findImage = (id) => {
    const url = assets.find((asset) => asset.sys.id === id);
    if (url) return "https:" + url?.fields?.file?.url;
  };

  const selectedDatas = datas.filter((data) => {
    if (category === "Kategori") {
      return data?.fields;
    } else {
      return data?.metadata.tags.find(({ sys }) => sys.id.trim() === category);
    }
  });

  return (
    <div className=" p-4">
      <div className="relative ">
        <select
          className="  md:flex border-[1px] border-[#00354E] w-auto outline-none  h-[38px]  rounded-[48px]  mb-20  px-5 mt-10   "
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value={"Kategori"}>{"Kategori"}</option>
          {categories.map((data) => (
            <option key={data.name} value={data.sys.id}>
              {data.name}
            </option>
          ))}
        </select>
        <img
          src={downArrowBlack}
          width={12}
          height={13}
          alt="downArrowBlack"
          className="absolute top-[55px] md:top-4 left-40 "
        />
      </div>

      <div className=" grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-y-20 lg:gap-x-72 2xl:grid-cols-3 2xl:gap-x-32 w-full ">
        {selectedDatas.slice(0, limit).map(({ fields }, index) => {
          return (
            <div
              key={index}
              className="shadow-[0px_5px_11px_1px_#00000026] max-w-[360px] rounded-[5px] md:max-w-[410px]"
            >
              <div
                // src={findImage(fields?.newsFeatureImage?.sys?.id) ?? noImage}
                style={{
                  backgroundImage: `url(${
                    findImage(fields?.newsFeatureImage?.sys?.id) ?? noImage
                  })`,
                }}
                width={410}
                height={214}
                alt="nyheter"
                className="w-[360px] h-[190px] md:w-[410px] md-[214px] object-cover bg-cover "
              />
              <div className="px-5 pt-2 pb-5 rounded-[5px] md:min-h-[300px] flex flex-col justify-between ">
                <div>
                  <p className="text-[14px] md:text-[16px]  font-[400] text-[#90979C] ">
                    {moment(fields?.newsDate).format("DD MMMM YYYY")}
                  </p>
                  <h3 className="text-[16px] md:text-[20px] font-[700]">
                    {fields?.newsHeading}
                  </h3>
                  <h4 className="text-[16]  md:text-[20px] font-[400] ">
                    {fields?.newsSubtitle}
                  </h4>
                </div>

                <button
                  className="border-[1px] border-[#00354E] w-[246px] h-[38px] md:w-[297px] md:h-[42px]  rounded-[48px] mt-6 flex justify-between items-center  px-5 "
                  onClick={() => navigate(`/nyheter/${fields?.homeSlug}`)}
                >
                  Läs mer om detta
                  <img
                    src={afterBlack}
                    width={16}
                    height={16}
                    alt="afterBlack"
                  />
                </button>
              </div>
            </div>
          );
        })}

        {selectedDatas.length === 0 && (
          <div className="w-full text-center font-semibold text-[18px]">
            No Data
          </div>
        )}
      </div>
      <div className="flex justify-end my-10 md:my-20 w-full md:px-16  ">
        {selectedDatas.length > limit && (
          <button
            className="border-[1px] border-[#00354E] w-[167px] h-[38px]  rounded-[48px]  flex justify-between items-center  px-5  "
            onClick={() => setLimit(limit + 6)}
          >
            Visa fler
            <img src={afterBlack} width={16} height={16} alt="afterBlack" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Contents;
