import React from "react";
import { useNavigate } from "react-router-dom";

const Optimerade = () => {
  const navigate = useNavigate();
  return (
    <div className="px-7 mt-10 md:hidden">
      <h2 className="text-[32px] font-[700] font-sans ">
        {" "}
        Optimerade miljöer för ett hållbart liv{" "}
      </h2>
      <h3 className="text-[16px] mt-4 max-w-[320px]">
        Med teknikens framsteg utrustas fastigheter med avancerade funktioner.
        Grundläggande system såsom el, VVS, uppvärmning, kylning, ventilation,
        belysning, brandskydd, säkerhet och låssystem kompletteras nu med smarta
        lösningar. Digitalisering och hållbarhet leder utvecklingen med
        automation, effektiv energianvändning, solenergisystem och
        laddningsstationer för eldrivna fordon, vilket speglas i vår strävan
        efter att skapa integrerade, energieffektiva byggnader. Läs mer om hur
        vi kan hjälpa dig som fastighetsägare till en optimerad miljö.
      </h3>
      <div className="flex justify-end">
        <button
          className="text-[15px] m-3 "
          onClick={() => navigate("/nyheter/Smarta fastigheter")}
        >
          {"Läs mer >>"}
        </button>
      </div>
    </div>
  );
};

export default Optimerade;
