import React from "react";
import skillnad from "../../assets/images/skillnad.png";
import visionBg from "../../assets/images/visionBg.png";
import downArrowBlack from "../../assets/icons/downArrowBlack.svg";
import { useNavigate } from "react-router-dom";

const Vision = () => {
  const navigate = useNavigate();
  return (
    <div className="my-16">
      <div className="px-5 md:hidden">
        <img src={skillnad} width={335} height={183} alt="skillnad" />
        <h3 className="text-[24px] mt-5 font-[600] ">
          Vår vision är att göra skillnad genom våra tjänster.
        </h3>

        <div className="max-w-[325px]">
          <h4 className="mt-5 text-[20px]">
            {" "}
            Vi lägger vikt vid att ta hand om vår planet. Därför strävar vi
            efter att erbjuda hållbara lösningar som omfattar hela livscykeln
            för fastigheter. Vi tar ansvar för olika teknikområden och hanterar
            både service och installation, vilket ger våra kunder möjligheten
            att samordna och optimera resurser, spara energi och effektivisera
            verksamheten.{" "}
          </h4>
        </div>

        <div className="flex justify-end">
          <button
            className="text-[20px] m-3 flex justify-center items-center gap-3 "
            onClick={() => navigate("/nyheter/skillnadtjanster")}
          >
            Läs mer
            <img
              src={downArrowBlack}
              width={18}
              height={18}
              alt={downArrowBlack}
              className={`-rotate-90`}
            />
          </button>
        </div>
      </div>

      <div
        className="px-32 hidden bg-cover md:flex flex-col justify-center  bg-no-repeat w-full h-[413px]"
        style={{
          backgroundImage: `url(${visionBg})`,
        }}
      >
        <h3 className=" text-[32px] lg:text-[36px] font-[700]  ">
          Vår vision är att göra skillnad genom våra tjänster.
        </h3>
        <h4 className="mt-5 text-[16px] lg:text-[20px]">
          {" "}
          Vi lägger vikt vid att ta hand om vår planet. Därför strävar vi efter
          att erbjuda hållbara lösningar som omfattar hela livscykeln för
          fastigheter. Vi tar ansvar för olika teknikområden och hanterar både
          service och installation, vilket ger våra kunder möjligheten att
          samordna och optimera resurser, spara energi och effektivisera
          verksamheten. Vårt tillvägagångssätt säkerställer att fastigheten
          fungerar smidigt, med regelbundet underhåll och proaktivt arbete.
          Genom att koppla samman olika installationer kan vi även övervaka och
          optimera anläggningar på distans, vilket ökar effektiviteten och
          minskar miljöpåverkan. Tillsammans med våra kunder strävar vi efter
          att utveckla innovativa lösningar som främjar hållbarhet och
          långsiktig framgång.
        </h4>
      </div>
    </div>
  );
};

export default Vision;
