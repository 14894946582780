import React from "react";
import gasell from "../assets/images/gasell.png";
import facbook from "../assets/images/facbook.png";
import emLogo from "../assets/images/emLogo.png";
import "@fontsource-variable/montserrat";
import { useNavigate } from "react-router-dom";

const links = [
  { id: 1, label: "Hem", link: "" },
  { id: 2, label: "Hållbarhet", link: "hallbarhet" },
  { id: 3, label: "Tjänster", link: "tjanster" },
  { id: 5, label: "Kontakt oss", link: "kontakt" },
  { id: 6, label: "Jobba med oss", link: "Jobba-med-ss" },
];

const FooterSection = () => {
  const navigate = useNavigate();
  return (
    <footer className="w-full">
      {/* desktop */}
      <div className="w-full h-[373px]   lg:h-full  px-5 lg:px-10 hidden md:block relative bottom-0  pt-14 bg-[#89BF50]  text-[#FFFFFF] ">
        <div className="flex  w-full justify-between lg:justify-start 2xl:justify-center gap-16 lg:gap-28 2xl:gap-44">
          <div className="flex justify-between w-[65%] lg:w-[58%] 2xl:w-[45%] gap-5 lg:gap-10  ">
            <div className="text-[16px] lg:text-[20px] font-[300] text-nowrap">
              <h4 className="text-[24px] lg:text-[32px] font-[600]">Kontakt</h4>
              <p className="mt-5 text-[16px]  lg:text-[20px] font-[600] lg:font-[400] ">
                Egestam Mattsson El & Data AB
              </p>
              <p>Ridspögatan 15a</p>
              <p>213 77 Malmö</p>
              <p className="mt-2 md:mt-4"> Tel: 040-6170166</p>
              <p>E-post: info@emeldata.se</p>
            </div>
            <div className="text-[16px] mt-[55px] lg:mt-[70px] font-[400]  flex flex-col gap-2 text-nowrap">
              <p className="md:text-[16px] lg:text-[18px] font-[600]">Meny</p>
              {links.map((data) => (
                <p
                  key={data.id}
                  className="cursor-pointer"
                  onClick={() => navigate(`${"/" + data.link}`)}
                >
                  {data.label}
                </p>
              ))}
            </div>
            <div className="text-[16px] mt-[55px] lg:mt-[70px] font-[400] flex flex-col gap-2 text-nowrap ">
              <p className="md:text-[16px] lg:text-[18px] font-[600]">
                Din integritet
              </p>
              <a
                href="https://emeldata.se/nyheter/GDPR"
                target="_blank"
                rel="noreferrer"
              >
                Integritetspolicy
              </a>
              <a
                href="https://emeldata.se/nyheter/Cookie policy"
                target="_blank"
                rel="noreferrer"
              >
                Cookiepolicy
              </a>
              <div className="flex  items-center gap-2">
                {" "}
                <img src={facbook} width={32} height={32} alt="facbook" />{" "}
                <a
                  href="https://www.facebook.com/p/Egestam-Mattsson-El-Data-AB-100054391965658/?locale=sv_SE"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </div>
              {/* <div className="flex  items-center gap-2">
                {" "}
                <img
                  src={Instagram}
                  width={32}
                  height={32}
                  alt="Instagram"
                />{" "}
                Instagram
              </div> */}
            </div>
          </div>
          <div className="flex justify-between w-[20%]  gap-10 mt-[50px]  lg:mt-[70px] 2xl:mt-0 mr-5 ">
            <div className="text-[18px] font-[400] flex flex-col gap-2">
              <p className="md:text-[20px] lg:text-[18px] 2xl:text-[32px] font-[600]">
                Gasellvinnare
              </p>
              <img
                src={gasell}
                width={268}
                height={128}
                alt="gasell"
                className="w-[203px] h-[93px] lg:w-[226px] lg:h-[107px] 2xl:w-[268px] 2xl:h-[128px] object-contain"
              />{" "}
              <p className=" hidden lg:block text-[12px] max-w-[300px]">
                Med målet att vara marknadsledande, kombinerar Egestam och
                Mattsson expertkunskap, engagemang och innovation för att bygga
                varaktiga kundrelationer och leverera arbete med fokus på
                kvalitet, miljömedvetenhet och säkerhet.
              </p>
            </div>
            <img
              src={emLogo}
              width={164}
              height={104}
              alt="em"
              className=" w-[140px] h-[104px] lg:w-[121px] lg:h-[77px] absolute bottom-5 lg:bottom-16 lg:right-10 2xl:right-20  object-contain"
            />{" "}
          </div>
        </div>
        <div className="bg-white h-[1px] absolute bottom-12  right-0 opacity-20 w-full hidden lg:block" />
        <p className="mt-8 lg:mt-20 lg:pb-3 text-[#CCCCCC] lg:text-white text-[16px] font-[400]  lg:text-center">
          Copyright © 2024 All rights reserved.
        </p>
      </div>
      {/* below tab screen */}
      <div className="flex   px-6 md:px-0 md:items-end md:pb-[40px] relative bottom-0  md:justify-start md:hidden  h-[618px] md:h-[597px] bg-[#89BF50] w-full   text-[#FFFFFF] ">
        <div className="flex flex-col   md:px-8 ">
          <div className="md:text-[16px]  font-[400] text-nowrap">
            <img
              src={emLogo}
              width={164}
              height={104}
              alt="em"
              className="min-w-[164px] md:hidden mt-3 h-[104px]"
            />{" "}
            <p className="text-[32px] mt-3 mb-1 md:mt-0 font-[600]">Kontakt</p>
            <p className="md:mt-1">Egestam Mattsson El & Data AB</p>
            <p>Ridspögatan 15a</p>
            <p>213 77 Malmö</p>
            <p className="mt-2"> Tel: 040-6170166</p>
            <p>E-post: info@emeldata.se</p>
          </div>
          <div className="flex gap-16 md:gap-20">
            <div className="md:text-[16px] mt-[45px] font-[400]  flex flex-col gap-2 text-nowrap">
              <p className="md:text-[18px] font-[600]">Meny</p>
              {links.map((data) => (
                <p
                  key={data.id}
                  className="cursor-pointer"
                  onClick={() => navigate(`${"/" + data.link}`)}
                >
                  {data.label}
                </p>
              ))}
            </div>
            <div className="bg-white h-[1px] absolute -left-10  mx-10  bottom-[295px] md:bottom-[255px]  opacity-20 w-full md:w-[50%]" />
            <div className="md:text-[16px] mt-[45px] font-[400] flex flex-col gap-2 text-nowrap">
              <p className="md:text-[18px] font-[600]">Din integritet</p>
              <a
                href="https://emeldata.se/nyheter/GDPR"
                target="_blank"
                rel="noreferrer"
              >
                Integritetspolicy
              </a>
              <a
                href="https://emeldata.se/nyheter/Cookie policy"
                target="_blank"
                rel="noreferrer"
              >
                Cookiepolicy
              </a>
              <div className="flex  items-center gap-2 mt-2">
                {" "}
                <img src={facbook} width={32} height={32} alt="facbook" />{" "}
                <a
                  href="https://www.facebook.com/p/Egestam-Mattsson-El-Data-AB-100054391965658/?locale=sv_SE"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </div>
              {/* <div className="flex  items-center gap-2 mt-2">
                {" "}
                <img
                  src={Instagram}
                  width={32}
                  height={32}
                  alt="facbook"
                />{" "}
                Instagram
              </div> */}
            </div>
          </div>
        </div>
        <div className="absolute hidden md:block right-32 bottom-48">
          <img
            src={emLogo}
            width={164}
            height={104}
            alt="em"
            className=" min-w-[164px] h-[104px]"
          />{" "}
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
