import React from "react";
import bravida from "../assets/images/bravida2.png";
import after from "../assets/icons/after.svg";
import { useNavigate } from "react-router-dom";

const Bravida = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col  md:hidden px-5 py-20 ">
      <img
        src={bravida}
        alt="bravida"
        width={499}
        height={274}
        className="w-[338px] h-[183px]  lg:w-[499px] lg:h-[274px] pr-14 md:pr-0 object-contain md:object-cover "
      />
      <h2 className="text-[32px]  mt-5 lg:hidden ">
        Hör av dig till oss
      </h2>
      <h3 className="text-[20px] font-[400] max-w-[338px] mt-3 lg:mt-3   ">
        Behöver du hjälp med något ? Kontakta oss idag!
      </h3>
      <button
        className="w-[233px] bg-[#00354E] py-2 rounded-[48px] text-white mt-3 flex justify-center items-center gap-2"
        onClick={() => navigate("/kontakt")}
      >
        Hitta till vårt kontor
        <img src={after} alt="after" width={18} height={18} />
      </button>
      <a
        href="tel:+46406170166"
        className="w-[198px] bg-[#00354E] py-2 rounded-[48px] text-white mt-3 justify-center items-center flex gap-2"
      >
        Ring oss direkt
        <img src={after} alt="after" width={18} height={18} />
      </a>
    </div>
  );
};

export default Bravida;
