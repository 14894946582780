import React, { useEffect, useState } from "react";
import noImage from "../../assets/images/noImage.jpg";
import arrowCercle from "../../assets/icons/arrowCercle.svg";
import { api } from "../../api/axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const NyheterSection = () => {
  const [datas, setDatas] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    api
      .get(
        "/entries?access_token=2BMWcZki8_EUyB_VwhdGv0N2rmx5YbIUaKoipK-tFaE&content_type=newsPosts2"
      )
      .then(({ data }) => {
        setDatas(data);
      })
      .catch((error) => alert(error.message));
  }, []);
  const findImage = (id) => {
    const url = datas?.includes?.Asset?.find((asset) => asset.sys.id === id);
    if (url) return "https:" + url?.fields?.file?.url;
  };
  datas?.items?.sort(
    (a, b) => new Date(b.sys.updatedAt) - new Date(a.sys.updatedAt)
  );

  return (
    <div className="px-5 py-10 lg:mt-14 md:px-20  lg:px-32  2xl:pl-52  ">
      <h2 className="text-[36px] font-[700] mb-5 flex items-center gap-2">
        <img
          src={arrowCercle}
          alt={arrowCercle}
          width={73}
          height={73}
          className="w-[30px] h-[30px] md:hidden md:h-[58px] md:w-[58px] lg:w-[73px] lg:h-[73px] "
        />
        Nyheter
      </h2>
      <div className="grid w-full grid-cols-1  md:grid-cols-2 2xl:grid-cols-3 gap-28  ">
        {datas?.items?.slice(0, 3)?.map(({ fields }, i) => (
          <div
            key={i}
            className=" max-w-[350px] cursor-pointer "
            onClick={() => navigate(`/nyheter/${fields?.homeSlug}`)}
          >
            <div
              // src={findImage(fields?.homePageImage?.sys?.id) ?? noImage}
              style={{
                backgroundImage: `url(${
                  findImage(fields?.newsFeatureImage?.sys?.id) ?? noImage
                })`,
              }}
              width={325}
              height={197}
              alt="optimerade"
              className="w-[304px] h-[181px] md:w-[325px md:h-[197px] object-contain bg-contain bg-no-repeat"
            />
            <p className="text-[#727272] uppercase text-[12px] mt-3  hidden md:block ">
              {fields?.homeSlug}
            </p>
            <p className="text-[#727272] text-[10px] font-[600] mt-3 hidden md:block">
              {moment(fields?.homeDateAndTime).format("DD-M-YYYY, h:mm")}
            </p>
            <h2 className="text-[32px] font-[700] mt-3">
              {fields?.homeHeading}
            </h2>
            <h3 className="text-[16px] mt-5">
              {fields?.homeParagraph?.content[0]?.content[0]?.value}
            </h3>
            <div
              className="flex justify-end"
              onClick={() => navigate(`/nyheter/${fields?.homeSlug}`)}
            >
              <button className="text-[15px] m-3  ">{"Läs mer >>"}</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NyheterSection;
