import React from "react";
import Installation from "../../assets/images/Installation.jpg";
import Nybyggnation from "../../assets/images/Nybyggnation.jpg";
import Renovering from "../../assets/images/Renovering.jpg";
import Elbilsladdare from "../../assets/images/Elbilsladdare.jpg";
import Solceller from "../../assets/images/Solceller.jpg";
import EListyr from "../../assets/images/EListyr.png";
import sectionsbg from "../../assets/images/sectionsbg.png";
import sectionslg from "../../assets/images/section-lg-bg.png";
import jonatan from "../../assets/images/jonatan.jpg";
import daniel from "../../assets/images/daniel.jpg";
import mail from "../../assets/icons/mail.svg";
import contact1 from "../../assets/images/contact1.jpg";
import contact2 from "../../assets/images/contact2.jpg";
import contact3 from "../../assets/images/egestam.jpg";
import contact4 from "../../assets/images/contact4.jpg";
import { useNavigate } from "react-router-dom";

const datas = [
  {
    title: "Installation",
    image: Installation,
    link: "Installationer",
  },
  {
    title: "Nybyggnation",
    image: Nybyggnation,
    link: "Nybyggnation",
  },
  {
    title: "Elbilsladdare",
    image: Elbilsladdare,
    link: "Elbilsladdare",
  },

  {
    title: "Solceller",
    image: Solceller,
    link: "Solceller",
  },
  {
    title: "Renovering",
    image: Renovering,
    link: "Renovering",
  },
  {
    title: "EL i styr",
    image: EListyr,
    link: "EL i styr",
  },
];
const peoples = [
  {
    image: daniel,
    name: "Daniel Egestam",
    description: "Projektchef - Delägare",
    mail: "daniel@emeldata.se",
  },
  {
    image: jonatan,
    name: "Jonatan Mattsson",
    description: "Affärschef - Delägare ",
    mail: "jonatan@emeldata.se",
  },
];

const contactImages = [contact1, contact2, contact3, contact4];

const Section = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* mobile */}
      <div className="flex flex-col justify-center w-full   mt-10 lg:hidden gap-5  ">
        {peoples.map((data, i) => (
          <div
            key={i}
            style={{
              backgroundImage: `url(${sectionsbg})`,
            }}
            className="h-[367px]  w-full bg-cover bg-no-repeat flex flex-col md:flex-row md:gap-28 px-5 justify-center md:items-center md:justify-start md:px-10"
          >
            <img
              src={data.image}
              width={212}
              height={180}
              alt={data.image}
              className="rounded-[300px] md:rounded-none w-[212px] h-[180px] md:h-[327px] md:w-[274px] object-cover md:object-contain "
            />
            <div className="mt-3">
              <h2 className="text-[24px] font-[600]">{data.name}</h2>
              <h4 className="text-[18px] font-[600]">{data.description}</h4>
              <a
                href={`mailto: ${data.mail}`}
                className="flex  items-center gap-2 mt-2 text-[20px]"
              >
                {" "}
                <img src={mail} width={20} height={20} alt="mail" /> Maila mig
              </a>
            </div>
          </div>
        ))}
      </div>

      {/*  macbook  */}
      <div className="lg:flex flex-col justify-center w-full items-center  my-16 hidden 2xl:hidden ">
        {peoples.map((data, i) => (
          <div
            key={i}
            style={{
              backgroundImage: `url(${sectionslg})`,
            }}
            className="h-[360px]  w-full bg-cover bg-no-repeat flex items-center pl-56"
          >
            <img
              src={data.image}
              width={374}
              height={250}
              alt={data.image}
              className=" object-cover w-[310px] h-[250px]"
            />
            <div className="mt-3 ml-48">
              <h2 className="text-[24px] font-[600]">{data.name}</h2>
              <h4 className="text-[18px] font-[600]">{data.description}</h4>
              <a
                href={`mailto: ${data.mail}`}
                className="flex  items-center  gap-2 mt-2 text-[20px]"
              >
                <img src={mail} width={20} height={20} alt="mail" /> Maila mig
              </a>
            </div>
          </div>
        ))}
      </div>

      {/* desktop */}
      <div className="2xl:flex flex-col justify-center w-full items-center my-16 hidden  ">
        <div
          style={{
            backgroundImage: `url(${sectionslg})`,
          }}
          className="h-[600px]  w-full bg-cover bg-no-repeat  flex items-center  justify-around"
        >
          {peoples.map((data, i) => (
            <div key={i} className="flex items-center gap-16 ">
              <img
                src={data.image}
                width={374}
                height={339}
                alt={data.image}
                className=" object-cover w-[374px] h-[339px]"
              />
              <div className="mt-3 ">
                <h2 className="text-[24px] font-[600]">{data.name}</h2>
                <h4 className="text-[18px] font-[600]">{data.description}</h4>
                <a
                  href={`mailto: ${data.mail}`}
                  className="flex  items-center  gap-2 mt-2 text-[20px]"
                >
                  <img src={mail} width={20} height={20} alt="mail" /> Maila mig
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h3 className="hidden lg:block font-[700] text-[48px] mx-16 2xl:mx-28">
        Läs mer om våra tjänster
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-16 my-24">
        {datas.map((data, i) => (
          <div
            key={i}
            style={{
              backgroundImage: `url(${data.image})`,
            }}
            onClick={() => navigate(`/nyheter/${data.link}`)}
            className="text-white cursor-pointer text-[32px] w-[288px] h-[216px] 2xl:w-[345px] 2xl:h-[259px] bg-cover rounded-[600px] lg:rounded-[400] flex justify-center items-center relative  overflow-hidden"
          >
            <div className=" bg-black absolute w-full h-full opacity-60  " />
            <h3 className="z-[1] text-center">{data.title}</h3>
          </div>
        ))}
      </div>
      <div className="hidden md:flex gap-10 mb-5  lg:my-32 justify-center 2xl:justify-between  2xl:px-20 flex-wrap ">
        {contactImages.map((data) => (
          <img
            key={data}
            src={data}
            width={356}
            height={317}
            alt={data}
            className="  w-[182px] h-[158px] lg:w-[225px] lg:h-[199px] 2xl:w-[356px] 2xl:h-[317px] object-cover rounded-[23px]   "
          />
        ))}
      </div>
    </>
  );
};

export default Section;
