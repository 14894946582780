import React from "react";
import optimeradeBg from "../../assets/images/optimeradeBg.png";
import { useNavigate } from "react-router-dom";

const OptimeradeMd = () => {
  const navigate = useNavigate();
  return (
    <div
      className="w-full bg-cover bg-no-repeat  cursor-pointer  text-white  px-14 lg:px-32 h-[397px] lg:h-[523px] hidden  md:flex flex-col justify-center items-center"
      style={{
        backgroundImage: `url(${optimeradeBg})`,
      }}
      onClick={() => navigate("/nyheter/Energieffektivitet")}
    >
      <div className="md:max-w-[610px] lg:max-w-[1100px] my-10">
        <h2 className="text-[32px] font-[700] ">
          Optimerade miljöer för ett hållbart liv
        </h2>
        <h3 className="text-[12px] lg:text-[20px] pt-4">
          Med teknikens framsteg utrustas fastigheter med avancerade funktioner.
          Grundläggande system såsom el, VVS, uppvärmning, kylning, ventilation,
          belysning, brandskydd, säkerhet och låssystem kompletteras nu med
          smarta lösningar. Digitalisering och hållbarhet leder utvecklingen med
          automation, effektiv energianvändning, solenergisystem och
          laddningsstationer för eldrivna fordon, vilket speglas i vår strävan
          efter att skapa integrerade, energieffektiva byggnader. Läs mer om hur
          vi kan hjälpa dig som fastighetsägare till en optimerad miljö. Som
          experter inom el- och datanätverksinstallationer är vi dedikerade till
          att erbjuda lösningar som är både tekniskt avancerade och
          miljövänliga. Vi arbetar nära våra kunder för att uppnå deras
          specifika behov och önskemål, och vår målsättning är att skapa
          fastigheter som är optimalt utrustade för framtida krav.
        </h3>
        <div className="flex justify-end">
          <button className="text-[15px] m-3 ">{"Läs mer >>"}</button>
        </div>
      </div>
    </div>
  );
};

export default OptimeradeMd;
