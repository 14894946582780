import React from "react";
import { useNavigate } from "react-router-dom";

const NordenSection = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#89BF50] w-full md:bg-opacity-15 flex justify-center md:min-h-[376px] lg:h-[435px ] text-white md:text-black px-6 md:px-20  ">
      <div className="lg:w-[800px] flex flex-col items-center">
        <h3 className="text-center pt-16 md:pt-20 text-[24px] lg:min-w-[871px] font-[400]">
          Norden ställer om till klimatneutrala samhällen. Egestam & Mattsson
          bistår dig som kund i att utveckla fastigheter och infrastruktur som
          förbättrar samhällets funktion nu och för framtiden.
        </h3>
        <p
          className="underline my-8 md:my-16 text-[16px] cursor-pointer"
          onClick={() =>
            navigate("/hallbarhet", {
              state: true,
            })
          }
        >
          Så här jobbar vi med hållbarhet
        </p>
      </div>
    </div>
  );
};

export default NordenSection;
