import React, { useState } from "react";
import downArrowWhite from "../../assets/icons/downArrowWhite.svg";

const Teknikområden = [
  "Avbrottsfri drift",
  "Automation - EL i styr",
  "Brandskydd och säkerhet",
  "EL",
  "Energioptimering",
  "Installationer",
  "Kraft",
  "Laddbox",
  "Samordnade installationer",
  "Servicetjänster",
  "Solceller",
  "Värmepumpar",
];

const Tjänteområden = [
  {
    title: "Fordonsladdning",
    description: "Komplett installation för fordonsladdning.",
  },
  {
    title: "Critical Power",
    description: "Verksamhetskritiska driftmiljöer utan avbrott. ",
  },
  {
    title: "Energy Management",
    description: "Ett helhetsgrepp över din energianvändning.",
  },
  {
    title: "Fastighetsautomation",
    description: "Styrning och integration av delsystem i fastigheten. ",
  },
  {
    title: "Brand & Säkerhet",
    description: "Helhetslösningar för brand, säkerhet, passage och lås. ",
  },
  {
    title: "Special Projects",
    description: "Stora och komplexa projekt.",
  },
];

const DropDown = () => {
  const [firstSection, setFirstSection] = useState(false);
  const [secondSection, setSecondSection] = useState(false);
  return (
    <div className="md:flex mb-10 z-[1] font-sans">
      <div
        className={`z-[1] flex-1 text-white  bg-[#00354E] px-5 md:px-10 md:py-6  ${
          firstSection ? "h-auto pb-16" : "h-[74px] md:h-[138px] "
        } `}
      >
        <div
          className={`flex justify-between items-center  w-full h-[74px]  cursor-pointer mb-10 `}
          onClick={() => setFirstSection(!firstSection)}
        >
          <h3 className="text-[24px] md:text-[36px] font-sans font-[300]  ">
            Teknikområden
          </h3>
          <img
            src={downArrowWhite}
            width={18}
            height={18}
            alt="downArrowWhite"
            className={`${firstSection && "rotate-180"}`}
          />
        </div>

        {firstSection &&
          Teknikområden.map((data) => (
            <h4 key={data} className="text-[16px] mt-2 ">
              {data}
            </h4>
          ))}
      </div>

      <div
        className={`flex-1 text-white  bg-[#89BF50] px-5 md:px-10 md:py-6 ${
          secondSection ? "h-auto pb-16" : "h-[74px] md:h-[138px]"
        } `}
      >
        <div
          className={`flex justify-between items-center  w-full h-[74px] cursor-pointer mb-10 `}
          onClick={() => setSecondSection(!secondSection)}
        >
          <h3 className="text-[24px] md:text-[36px]  font-[300]  ">
            Tjänteområden
          </h3>
          <img
            src={downArrowWhite}
            width={18}
            height={18}
            alt="downArrowWhite"
            className={`${secondSection && "rotate-180"}`}
          />
        </div>

        {secondSection &&
          Tjänteområden.map((data, i) => (
            <div key={i} className="">
              <h4 className="text-[20px] mt-2  font-[700]">{data.title}</h4>
              <h4 className="text-[20px] mt-2 font-[300] max-w-[260px]">
                {data.description}
              </h4>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DropDown;
