import React from "react";
import Navbar from "../navbar/Navbar";

const HeroSection = ({
  bannerDesktope,
  bannerMobile,
  newsHeading,
  homeSlug,
}) => {
  return (
    <div className="w-full relative">
      <div
        className="w-full h-[506px]  lg:h-[559px] bg-cover bg-no-repeat hidden md:block "
        style={{
          backgroundImage: `url(${bannerDesktope})`,
        }}
      />
      <div
        className="w-full h-[506px]  lg:h-[559px] bg-cover bg-no-repeat md:hidden  "
        style={{
          backgroundImage: `url(${bannerMobile})`,
        }}
      />
      <div className="bg-[#00354E] absolute   top-0  w-full h-full opacity-60" />
      <div className="absolute   top-0 w-full">
        <Navbar />

        <div className="flex flex-col items-center justify-center md:items-start h-full w-full">
          <div className=" mx-10  mt-16  md:mx-16  lg:mx-28 md:text-start font-[400] md:font-[400] md:my-5 lg:my-10 lg:max-w-[1350px] text-white">
            <h1 className=" text-white font-[700] text-[32px] md:text-[64px] md:leading-[60px] ">
              {newsHeading}
            </h1>
          </div>
        </div>
      </div>
      <h6 className=" text-start absolute bottom-5 left-10  md:bottom-16 md:left-16 lg:left-28 text-[#89BF50]  text-[12px]  font-[500] md:font-[500] md:text-[20px]  md:max-w-[782px] lg:max-w-[1000px]  ">
        <span className="md:hidden">Ämne: </span> {homeSlug}
      </h6>
    </div>
  );
};

export default HeroSection;
