import React from "react";
import HeroSection from "../components/services/HeroSection";
import DropDown from "../components/services/DropDown";
import FooterSection from "../components/FooterSection";
import Vision from "../components/services/Vision";
import Sections from "../components/services/Sections";
import ScrollToTop from "../components/scrollToTop";
import { getMetaDescription } from "../utils/metaDescriptions";
import { Helmet } from "react-helmet";

const Service = () => {
  const metaDescription = getMetaDescription("tjanster");
  return (
    <>
      <Helmet>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <ScrollToTop />
      <HeroSection />
      <DropDown />
      <Vision />
      <Sections />
      <FooterSection />
    </>
  );
};

export default Service;
