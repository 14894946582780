import React from "react";
import contact from "../../assets/videos/contact.mp4";
import Navbar from "../navbar/Navbar";

const Hero = () => {
  return (
    <div className="relative w-full  h-[219px] md:h-[439px] lg:h-[507px] 2xl:h-[567px]">
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute inset-0   w-full  h-[219px] md:h-[439px] lg:h-[507px] 2xl:h-[567px] object-cover"
      >
        <source src={contact} type="video/mp4" />
      </video>

      <div className="bg-[#00354E] absolute   top-0  w-full h-full opacity-60" />
      <div className="relative z-10 ">
        <Navbar />

        <div className=" flex-col items-center justify-center  h-full w-full hidden 2xl:flex text-white text-[74px] font-[700]">
          Kontakta oss
        </div>
      </div>
    </div>
  );
};

export default Hero;
