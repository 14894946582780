import React, { useEffect } from "react";
import klimatmål from "../../assets/images/vara.png";
import arrowCercle from "../../assets/icons/arrowCercle.svg";
import { useLocation } from "react-router-dom";

const klimatmal = [
  {
    id: 1,
    year: "2030",
    title: "Reduktion & Innovation",
    description:
      "Inom de kommande åren sätter vi fokus på att minska våra CO2-utsläpp med 20% från 2020 års nivåer. Vi kommer att investera i innovativa teknologier och arbetsmetoder som minskar vårt klimatavtryck och ökar energieffektiviteten i de fastigheter vi arbetar med.",
  },
  {
    id: 2,
    year: "2035",
    title: "Partnerskap & Ledarskap",
    description:
      "Till 2035 siktar vi på att hjälpa våra kunder att minska deras utsläpp med 50 % jämfört med 2020, samtidigt som vi uppnår samma minskning i vår egen verksamhet. Vi kommer att fördjupa våra partnerskap och ta en ledande roll i branschen för att driva på den gröna omställningen.",
  },
  {
    id: 3,
    year: "2040",
    title: "Förnyelse & Optimering",
    description:
      "Fram till 2040 kommer vårt arbete att fokusera på att optimera användningen av förnybar energi och maximera resurseffektiviteten i alla våra projekt. Vi strävar efter att våra fastighetslösningar ska vara helt självförsörjande när det gäller energi och minimera avfall till nära noll.",
  },
  {
    id: 4,
    year: "2045",
    title: "Netto noll",
    description:
      "Vår resa mot 2045 är inte bara en plan, det är ett löfte om att aktivt forma en hållbar framtid där vi och våra kunder tillsammans kan göra en verklig skillnad. Vår slutdestination är en verksamhet med netto noll utsläpp senast 2045. Vi kommer att ha fullt integrerat hållbara principer, från design och installation till underhåll och service. ",
  },
];

const Vara = () => {
  const location = useLocation();
  useEffect(() => {
    if (location?.state) {
      const targetSection = document.getElementById("target-section");
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location?.state]);
  return (
    <div
      id="target-section"
      className="w-full hidden scroll-mt-24  md:flex flex-col justify-center items-center bg-gradient-to-b from-[#E9F3F9] via-[#E9F3F9] to-[#FFFFFF] pb-24 relative"
    >
      <div className="bg-[#89BF50] w-full h-[141px]  ">
        <h2 className="text-[48px] lg:text-[64px] font-[700] w-full text-white text-center pt-8 lg:pt-5  ">
          Våra klimatmål
        </h2>
      </div>

      <img
        src={klimatmål}
        width={503}
        height={327}
        alt="klimatmål"
        className="my-20  "
      />
      <div className="grid md:grid-cols-2 gap-14 md:gap-x-16  lg:gap-x-24 2xl:gap-x-96">
        {klimatmal.map((data) => (
          <div
            key={data.id}
            className="px-2 max-w-[390px]  lg:w-[450px] 2xl:w-[384px] flex flex-col justify-center items-center"
          >
            <div className="flex gap-2">
              <img
                src={arrowCercle}
                alt={arrowCercle}
                width={73}
                height={73}
                className="w-[35px] h-[29px]  "
              />
              <h3 className="text-[20px] font-[700]">{data.year}</h3>
            </div>
            <h3 className="text-[20px] font-[700]">{data.title} </h3>

            <p className="text-[16px] my-5 text-center ">{data.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vara;
