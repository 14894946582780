import React from "react";
import hallbar from "../../assets/images/hallbar.png";
import anstandiga from "../../assets/images/anstandiga.png";
import industri from "../../assets/images/industri.png";
import innovation from "../../assets/images/innovation.png";
import bekampa from "../../assets/images/bekampa.png";

const Hallbar = () => {
  const datas = [
    {
      id: 1,
      icon: hallbar,
      title: "Hållbar energi för alla",
      description:
        "Genom att integrera solenergilösningar och energieffektiviseringsmetoder i våra installationer, arbetar vi för att göra hållbar energi tillgänglig och överkomlig.",
    },
    {
      id: 2,
      icon: anstandiga,
      title: " Anständiga arbetsvillkor och ekonomisk tillväxt",
      description:
        "Vi satsar på att vara en ansvarsfull arbetsgivare som främjar säkra arbetsförhållanden, samt stödjer kontinuerlig utbildning och utveckling av våra medarbetare.",
    },
    {
      id: 3,
      icon: industri,
      title: " Industri, innovation och infrastruktur",
      description:
        "Vi bidrar till byggandet av motståndskraftig infrastruktur och främjar inkluderande och hållbar industrialisering genom att införa innovativa och hållbara tekniska lösningar.",
    },
    {
      id: 4,
      icon: innovation,
      title: "Hållbara städer och samhällen",
      description:
        "Genom vårt arbete med att utveckla och renovera fastigheter och infrastruktur strävar vi efter att skapa och underhålla gröna och tillgängliga urbana områden.",
    },
    {
      id: 5,
      icon: bekampa,
      title: "Bekämpa klimatförändringarna",
      description:
        "Vårt engagemang för att minska koldioxidutsläpp och vår inriktning på klimatanpassning i alla våra projekt reflekterar vårt bidrag till kampen mot klimatförändringar.",
    },
  ];
  return (
    <div className="hidden md:flex flex-col items-center justify-center  mb-20 w-full  ">
      <h2 className="text-[20px] font-[700] text-nowrap bg-inherit  md:bg-[#00354E] md:h-[127px]   w-full px-5 md:text-white text-center md:text-[32px] md:pt-10 lg:h-[141px]  lg:text-[48px] ">
        {" "}
        Hur vi bidrar till de Globala Målen
      </h2>
      <h3 className="text-[20px] font-[600] max-w-[330px] text-center md:hidden ">
        -Vår policy för att bidra till de Globala Målen
      </h3>
      <div className="flex flex-col lg:flex-row flex-wrap justify-center items-center gap-10 2xl:gap-x-72 w-full  ">
        {datas.map((data) => (
          <div
            key={data.id}
            className="max-w-[300px] md:max-w-[604px] lg:w-[580px] 2xl:w-[631px] flex flex-col justify-center items-center gap-4 mt-10 md:mt-20 px-5"
          >
            <img src={data.icon} width={198} height={198} alt="hallbar" />
            <h3 className="text-[16px] font-[700] text-center lg:text-[20px] ">
              {data.title}
            </h3> 
            <p className="text-[16px] md:text-[20px] text-center ">
              {data.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hallbar;
