import React from "react";
import figure from "../../assets/images/figure.png";
import egestam from "../../assets/images/egestam.jpg";
import Bravida from "../Bravida";
import { useNavigate } from "react-router-dom";

const EgestamSection = () => {
  const navigate = useNavigate();
  return (
    <div className=" w-full  ">
      {/* for mobile */}
      <div className="flex flex-col px-5 pt-20  md:hidden">
        <img
          src={figure}
          width={772}
          height={441}
          alt="figure"
          className="w-[340px] h-[177px] object-cover "
        />
        <h3 className="text-[20px] font-[600] my-3">Vi gör skillnad</h3>
        <h2 className="text-[36px] font-[700] max-w-[330px] leading-[42px]">
          Egestam & Mattsson satsar på en hållbar affär
        </h2>
        <h3 className="text-[20px] font-[500] font-sans max-w-[331px]  ">
          Hållbar framtid en del ev vår affär vår policy för att bidra till de
          Globala Målen
        </h3>
        <h3 className="text-[16px] font-[300] mt-10 max-w-[340px]">
          Egestam och Mattsson har antagit en policy som aktivt stödjer de
          globala målen för hållbar utveckling. Denna policy innefattar
          åtaganden att minska miljöpåverkan från verksamheten, främja
          energieffektivitet i alla projekt, och bidra till att bygga hållbara
          samhällen. Företaget strävar efter att integrera hållbara metoder i
          alla aspekter av vår affärsmodell, från design och installation till
          drift och underhåll. Genom att arbeta nära med kunder och
          samarbetspartners, siktar Egestam och Mattsson på att vara en
          drivkraft för positiv förändring och innovation inom sitt område, i
          linje med de globala hållbarhetsmålen.
        </h3>
        <div className="flex justify-center">
          <button
            className="w-[242.27px]   h-[60px] border-[2px] border-[#89BF50] rounded-[19px] text-[20px] font-[700] text-[#89BF50] mt-8"
            onClick={() => navigate("/hallbarhet")}
          >
            Hållbarhet
          </button>
        </div>
      </div>

      {/* for tablets */}
      <div
        className="text-black md:text-white  bg-white hidden md:flex flex-col  bg-cover lg:hidden min-h-[431px]  pt-16 relative  "
        style={{
          backgroundImage: `url(${egestam})`,
        }}
      >
        <h2 className="text-[36px]  font-[700]  hidden md:block mx-14  z-[1]">
          Egestam & Mattsson satsar på en hållbar affär
        </h2>
        <div className=" my-5 gap-6 hidden md:flex  z-[1] px-14">
          <div className="z-10">
            <h3 className="max-w-[500px] text-[24px] lg:text-nowrap">
              Hållbar framtid en del ev vår affär vår policy för att bidra till
              de Globala Målen
            </h3>
            <button
              className="w-[242.27px]   h-[60px] border-[2px] border-white  text-[20px] font-[700] text-white mt-14"
              onClick={() => navigate("/hallbarhet")}
            >
              Hållbarhet
            </button>
          </div>
          <h3 className="text-[12px] font-[400]  max-w-[470px] mb-5 z-10">
            Egestam och Mattsson har antagit en policy som aktivt stödjer de
            globala målen för hållbar utveckling. Denna policy innefattar
            åtaganden att minska miljöpåverkan från verksamheten, främja
            energieffektivitet i alla projekt, och bidra till att bygga hållbara
            samhällen. Företaget strävar efter att integrera hållbara metoder i
            alla aspekter av vår affärsmodell, från design och installation till
            drift och underhåll. Genom att arbeta nära med kunder och
            samarbetspartners, siktar Egestam och Mattsson på att vara en
            drivkraft för positiv förändring och innovation inom sitt område, i
            linje med de globala hållbarhetsmålen.
          </h3>
        </div>

        <div className="bg-[#00354E] absolute   top-0  w-full h-full opacity-60" />
      </div>

      {/* for desktop */}
      <div
        className="text-black md:text-white  bg-white hidden lg:block   min-h-[562px] bg-cover  relative"
        style={{
          backgroundImage: `url(${egestam})`,
        }}
      >
        <div className="bg-[#00354E] absolute   top-0  w-full h-full opacity-60" />

        <div className=" mt-5 flex justify-between gap-14 items-center mx-14 px-10 pt-10">
          <div className="max-w-[900px] z-[1] ">
            <h3 className="text-[20px] font-[600]">Vi gör skillnad</h3>
            <h2 className="text-[36px] font-[700] hidden md:block ">
              Egestam & Mattsson satsar på en hållbar affär
            </h2>
            <h3 className=" text-[32px] mt-4 max-w-[665px] ">
              Hållbar framtid en del ev vår affär vår policy för att bidra till
              de Globala Målen
            </h3>
            <h3 className="text-[16px] font-[400] mt-10  max-w-[789px]  ">
              Egestam och Mattsson har antagit en policy som aktivt stödjer de
              globala målen för hållbar utveckling. Denna policy innefattar
              åtaganden att minska miljöpåverkan från verksamheten, främja
              energieffektivitet i alla projekt, och bidra till att bygga
              hållbara samhällen. Företaget strävar efter att integrera hållbara
              metoder i alla aspekter av vår affärsmodell, från design och
              installation till drift och underhåll. Genom att arbeta nära med
              kunder och samarbetspartners, siktar Egestam och Mattsson på att
              vara en drivkraft för positiv förändring och innovation inom sitt
              område, i linje med de globala hållbarhetsmålen.
            </h3>
            <div className="flex justify-center">
              <button
                className="w-[242.27px]   h-[60px] border-[2px] border-white  text-[20px] font-[700] text-white my-6"
                onClick={() => navigate("/hallbarhet")}
              >
                Hållbarhet
              </button>
            </div>
          </div>

          <img
            src={figure}
            width={772}
            height={441}
            alt="figure"
            className="w-[335px] h-[177px] object-cover 2xl:w-[772px] 2xl:h-[400px]  z-[1]"
          />
        </div>
      </div>

      <Bravida />
    </div>
  );
};

export default EgestamSection;
