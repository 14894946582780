import React from "react";
import automation from "../../assets/images/automation.png";
import after from "../../assets/icons/after.svg";
import { useNavigate } from "react-router-dom";

const EnergieffektivaSection = () => {
  const navigate = useNavigate();
  return (
    <div className="py-8 px-5 md:py-0 md:px-0   lg:py-20  flex flex-col lg:gap-16 2xl:gap-0 lg:items-end  lg:flex-row-reverse lg:justify-around w-full  2xl:items-center   ">
      <img
        src={automation}
        alt="automation"
        width={520}
        height={300}
        className="w-[279px] h-[144px] object-cover md:hidden lg:block lg:w-[469px] lg:h-[269px] lg:mr-10 2xl:mx-72 "
      />
      <div className="font-400 md:ml-16 lg:ml-28 2xl:ml-48 max-w-[625px] md:hidden lg:block  ">
        <h2 className="text-[32px] md:text-[48px] mt-5 leading-[55px]">
          Energieffektiva lösningar inom elinstallation
        </h2>
        <h3 className="text-[18px] mt-2 max-w-[319px]  md:max-w-full md:mt-5">
          Eftersom energikostnaden är löpande blir energioptimering en
          investering både för dig och för miljön. Genom att underhålla,
          uppgradera och digitalisera fastigheter kan vi minska din
          energianvändning. Låt oss på Egestam & Mattsson ge förslag på
          lösningar och göra konkreta insatser.   
        </h3>
        <button
          className="bg-[#89BF50] text-[16px] text-white max-w-[330px] md:max-w-[500px] rounded-[48px] py-2 px-14 md:px-16 md:mt-5  relative  "
          onClick={() => navigate("/nyheter/Nyheter")}
        >
          Så här jobbar vi med energioptimering{" "}
          <img
            src={after}
            alt="after"
            width={18}
            height={18}
            className="absolute bottom-2 right-14 md:right-5"
          />
        </button>
      </div>
      <div
        className="hidden md:block lg:hidden bg-blue-300 w-full bg-no-repeat bg-cover h-[433px] text-white relative"
        style={{
          backgroundImage: `url(${automation})`,
        }}
      >
        <div className="w-full h-full bg-[#00354E] absolute opacity-60 z-0"></div>
        <div className="font-400 md:ml-10 lg:ml-28 2xl:ml-48 max-w-[700px] h-full flex flex-col justify-center absolute">
          <h2 className="text-[32px] md:text-[48px] mt-5 leading-[50px]">
            Energieffektiva lösningar inom elinstallation
          </h2>
          <h3 className="text-[18px] mt-2 ">
            Eftersom energikostnaden är löpande blir energioptimering en
            investering både för dig och för miljön. Genom att underhålla,
            uppgradera och digitalisera fastigheter kan vi minska din
            energianvändning. Låt oss på Egestam & Mattsson ge förslag på
            lösningar och göra konkreta insatser.   
          </h3>
          <button
            className="bg-[#89BF50] text-[16px] text-white w-[330px] md:w-[460px] rounded-[48px] py-1 px-20 md:px-0 mt-10 relative  "
            onClick={() => navigate("/nyheter/Nyheter")}
          >
            Så här jobbar vi med energioptimering{" "}
            <img
              src={after}
              alt="after"
              width={18}
              height={18}
              className="absolute bottom-2 right-14 md:right-5"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnergieffektivaSection;
