import React from "react";
import bgVideo from "../../assets/videos/hallbarhet.mp4";
import Navbar from "../navbar/Navbar";

const HeroSection = () => {
  return (
    <div className="relative w-full h-[660px] md:h-[862px] lg:h-[931px] 2xl:h-[1001px] object-cover">
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute inset-0   w-full h-[660px] md:h-[862px] lg:h-[931px] 2xl:h-[1001px] object-cover"
      >
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="bg-[#00354E] absolute   top-0  w-full h-full opacity-60" />
      <div className="relative z-10 ">
        <Navbar />

        <div className="flex flex-col items-center justify-center h-full w-full">
          <div className="   max-w-[300px]  md:max-w-[782px] lg:max-w-full my-20 md:leading-[70px] text-[20px] md:text-[64px] md:mx-16  md:text-start font-[400] md:font-[400] md:my-44 2xl:my-52 text-white">
            <h1 className=" text-[#89BF50] text-center md:text-start font-[700] text-[32px] md:text-[48px] lg:text-[64px] leading-[45px] ">
              Hållbarhet{" "}
              <span className="text-white "> för en bättre framtid</span>
            </h1>
            <h2 className="text-[16px] text-center md:hidden mt-3  ">
              Vårt engagemang för en bättre framtid är tydligt. Vi strävar efter
              att minska miljöpåverkan och främja teknisk innovation. Här
              berättar vi mer om hur vi driver förändring och främjar{" "}
              <span className="text-[#89BF50]">hållbarhet</span> i allt vi gör.
            </h2>
            <h2 className="hidden  md:block md:text-[20px] font-[400] md:font-[500] md:leading-[30px] md:mt-5 md:max-w-[782px] lg:max-w-[941px]  lg:pt-8">
              Vårt engagemang för en bättre framtid är tydligt. Vi strävar efter
              att minska miljöpåverkan och främja teknisk innovation. Genom att
              erbjuda energieffektiva lösningar, integrerade system, och smarta
              teknologier, arbetar vi aktivt för att skapa miljövänliga
              fastigheter och infrastruktur. Här berättar vi mer om hur vi
              driver förändring och främjar hållbarhet i allt vi gör.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
