// import React, { useEffect, useState } from "react";
// import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

// const center = {
//   lat: 7.2905715,
//   lng: 80.6337262,
// };

const Address = () => {
  // const [visible, setVisible] = useState(window.innerWidth > 800);

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
  // });

  // useEffect(() => {
  //   const updateWindowWidth = () => {
  //     setVisible(window.innerWidth > 800);
  //   };

  //   window.addEventListener("resize", updateWindowWidth);

  //   return () => {
  //     window.removeEventListener("resize", updateWindowWidth);
  //   };
  // }, []);

  return (
    <div className="px-5 pt-8  flex  md:px-16 gap-16 lg:gap-20 2xl:px-44 ">
      <div>
        <h2 className="text-[36px] font-[700] mb-5">Kontaktuppgifter</h2>
        <h5 className="text-[20px] font-[600] mb-5">Kontor</h5>
        <p className="text-[20px] font-[300]">
          <span className="text-[#149A39]">
            info@emeldata.se <br /> (+46) 040-6170166 <br />
          </span>{" "}
          Telefontider: 08:00-17:00 <br /> Måndag-Fredag
        </p>
        <p className="text-[20px] mt-10 leading-10">
          Egestam Mattsson El & Data AB <br /> Ridspögatan 15a <br /> 213 77
          Malmö
        </p>
      </div>

      {/* {visible && isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: "400px",
            height: "400px",
          }}
          center={center}
          zoom={10}
        >
          <Marker position={center} />
        </GoogleMap>
      )} */}
    </div>
  );
};

export default Address;
